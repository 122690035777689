import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import VueFriendlyIframe from "vue-friendly-iframe";
Vue.use(VueFriendlyIframe);

import PrimeVue from "primevue/config";
Vue.use(PrimeVue);

import ConfirmationService from "primevue/confirmationservice";
Vue.use(ConfirmationService);

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

new Vue({
	router,
	store,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
