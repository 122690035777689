<template>
	<div>
		<Menubar :model="menuItems" />

		<div v-if="chili_editorURL">
			<iframe
				scrolling="no"
				frameborder="0"
				ref="chili_iframe"
				v-show="!chili_documentSaved"
				crossorigin="anonymous"
				allow=""
				@load="chiliEditorLoaded"
				style="display: block; position: relative; width: 100%; height: calc(100vh); margin: 0; padding: 0"
				:src="chili_editorURL"
			></iframe>
		</div>
		<ConfirmDialog></ConfirmDialog>
	</div>
</template>

<script>
// @ is an alias to /src

//view-source:https://www.clickandorderstore.nl/advanced-editor/plain.asp?environment=clickandorderstore_nl&document_id=a0570a5a-2489-4a18-bbfe-f2519dade14f&document_id_temp=&key=KgaM9cik_P8XmgQ5niVnuGSZgvV5LjwWkg7+EqlepDz5bineECw37XGHdUs3RCaf9vn9Q2KOLRimGMOCOVMnJXrJuHOpEMtYQqZ4+JWyUMY=&workspace_id=5bae1e68-e63b-46cc-b461-7d08757bbac9&viewpreferences_id=284920cd-0c92-4bd7-bfd7-8e4083ba61dc&settings_id=43486c8b-a357-4c8a-9f26-c0fe53154709&path_temp=ppg_retail_nl/a0570a5a-2489-4a18-bbfe-f2519dade14f/&document_name_temp=1398&ignoredatasource=false&enable3D=true&editor_QUERYSTRING=|setqsvars=true|Naam=Targa%2520Web%2520en%2520Print%2520BV|Adres=Molenwerf%252030|Postcode=1911%2520DB|Plaats=Uitgeest|E-mailadres=alex%40targateam.nl|Website=http%253A%2F%2Fwww.targateam.nl|Logo=407

const axios = require("axios");
const convert = require("xml-to-json-promise");
const parseString = require("xml2js").parseString;
const htmlEncode = require("js-htmlencode");

import Menubar from "primevue/menubar";
import ConfirmDialog from "primevue/confirmdialog";

export default {
	name: "Home",
	components: { Menubar, ConfirmDialog },
	data() {
		return {
			menuItems: [
				{
					label: "Quit",
					icon: "pi pi-fw pi-power-off",
					command: () => {
						this.postMessageToParent({
							action: "close",
						});
					},
				},
				{
					label: "Save",
					icon: "pi pi-fw pi-save",
					command: this.saveDocument,
					visible: this.parentReady,
				},
				/*
				{
					label: "Undo changes",
					icon: "pi pi-fw pi-undo",
					command: this.revertDocument,
				},
				*/
			],
			chili_API_baseURL: "/CHILI",
			chili_editorURL: null,
			chili_documentCopy: null,
			chili_documentSaved: false,
			parentReady: false,
		};
	},
	computed: {
		chili_key() {
			return this.$route.query.key;
		},
		chili_environment() {
			return this.$route.query.environment;
		},
		chili_document_id() {
			return this.$route.query.document_id;
		},
		chili_document_id_temp() {
			return this.chili_documentCopy
				? this.chili_documentCopy.id
				: this.$route.query.document_id_temp;
		},
		chili_workspace_id() {
			return this.$route.query.workspace_id;
		},
		chili_viewpreferences_id() {
			return this.$route.query.viewpreferences_id;
		},
		chili_settings_id() {
			return this.$route.query.settings_id;
		},
		chili_path_temp() {
			return this.$route.query.path_temp;
		},
		chili_document_name_temp() {
			return this.$route.query.document_name_temp;
		},
	},
	methods: {
		async chiliSoapCall(
			chili_operation,
			chili_apikey,
			chili_vars_array,
			includeXML = false
		) {
			let self = this;

			let chili_vars = "";
			if (chili_apikey != "") {
				chili_vars =
					chili_vars + "<apiKey>" + chili_apikey + "</apiKey>";
			}
			if (chili_vars_array) {
				chili_vars_array.forEach((variable) => {
					chili_vars =
						chili_vars +
						"<" +
						variable.name +
						">" +
						variable.value +
						"</" +
						variable.name +
						">";
				});
			}

			let chili_soap_xml =
				//'<?xml version="1.0" encoding="utf-8"?>' +
				'<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">' +
				"  <soap12:Body>" +
				"    <" +
				chili_operation +
				' xmlns="http://www.chili-publisher.com/">' +
				"      " +
				chili_vars +
				"    </" +
				chili_operation +
				">" +
				"  </soap12:Body>" +
				"</soap12:Envelope>";

			return await axios
				.post("/CHILI/main.asmx", chili_soap_xml, {
					headers: {
						"Content-Type": "text/xml",
					},
				})
				.then((response) => {
					if (response.data) {
						console.log(chili_operation, response);
						return convert
							.xmlDataToJSON(response.data)
							.then((json) => {
								if (
									json &&
									json["soap:Envelope"] &&
									json["soap:Envelope"]["soap:Body"] &&
									json["soap:Envelope"]["soap:Body"][0] &&
									json["soap:Envelope"]["soap:Body"][0][
										chili_operation + "Response"
									] &&
									json["soap:Envelope"]["soap:Body"][0][
										chili_operation + "Response"
									][0] &&
									json["soap:Envelope"]["soap:Body"][0][
										chili_operation + "Response"
									][0][chili_operation + "Result"]
								) {
									let result =
										json["soap:Envelope"]["soap:Body"][0][
											chili_operation + "Response"
										][0][chili_operation + "Result"];
									if (result && result[0]) {
										return convert
											.xmlDataToJSON(result[0])
											.then((json) => {
												//console.log(chili_operation, json);
												if (includeXML) {
													return {
														json: json,
														xml: result[0],
													};
												} else {
													return json;
												}
											});
									}
									return null;
								}
								return null;
							});
					}
				});
		},

		async getChiliKey() {
			let self = this;
			await axios({
				method: "post",
				url: self.chili_API_baseURL + "/rest-api/v1/system/apikey",
				withCredentials: true,
				data: {
					userName: "ApiUser",
					password: "msfh69",
				},
				params: {
					environmentNameOrURL: self.chili_environment,
				},
			})
				.then(function (response) {
					console.log(JSON.stringify(response.data));
					return convert.xmlDataToJSON(response.data).then((json) => {
						console.log(json);
						return json.item.$;
					});
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async copyChiliDocument() {
			let self = this;
			console.log("chili_document_id_temp", self.chili_document_id_temp);
			return await axios({
				method: "post",
				url:
					self.chili_API_baseURL +
					"/rest-api/v1/resources/Documents/items/" +
					self.chili_document_id +
					"/copy",
				headers: {
					"API-KEY": self.chili_key,
				},
				withCredentials: true,
				data: {},
				params: {
					newName: self.chili_document_name_temp,
					folderPath: self.chili_path_temp,
				},
			})
				.then(function (response) {
					return convert.xmlDataToJSON(response.data).then((json) => {
						console.log("json", json);
						return json.item.$;
					});
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getChiliEditorURL() {
			let self = this;

			return (
				"/CHILI/" +
				self.chili_environment +
				"/editor_html.aspx" +
				"?doc=" +
				self.chili_document_id_temp +
				"&apiKey=" +
				self.chili_key +
				"&ws=" +
				self.chili_workspace_id +
				"&vp=" +
				self.chili_viewpreferences_id +
				"&setqsvars=true&Naam=sdfgsdfg&Adres=asfgsdfg&E-mailadres=sdfgdfghfhgdfgh"
			);
		},
		chiliEditorLoaded() {
			let self = this;
			window.chili_iframe = self.$refs["chili_iframe"].contentWindow;
			window.chili_iframe.GetEditor(self.editorLoaded);
		},
		editorLoaded(jsInterface) {
			let self = this;
			console.log("xxx", window.chili_iframe);
			window.editor = window.chili_iframe.editorObject;

			/*
			self.chili_iframe.testalex = function (numberinput) {
				return getChiliVariableByName("Entity");
			};
			self.chili_iframe.chiliPhoneFormat = function (pf_prefix, pf_number, pf_numberarray) {
				//alert(pf_numberarray);
				if ($.isArray(pf_numberarray)) {
					pf_numberlength = 0;
					for (var i = 0; i < pf_numberarray.length; i++) {
						pf_numberlength = pf_numberlength + pf_numberarray[i];
					}
					pf_prefix = getChiliVariableByName(pf_prefix);
					pf_number = getChiliVariableByName(pf_number);
					pf_phoneformatted = "";
					console.log(pf_number.length);
					//if(pf_numberlength == pf_number.length) {

					if (pf_number != "") {
						pf_phoneformatted = "";
						pf_startpos = 0;
						for (var i = 0; i < pf_numberarray.length; i++) {
							pf_phoneformatted = pf_phoneformatted + pf_number.substr(pf_startpos, pf_numberarray[i]) + " ";
							pf_startpos = pf_startpos + pf_numberarray[i];
						}
						pf_phoneformatted = pf_prefix + pf_phoneformatted.trim();
					}
					console.log(pf_numberarray + ": " + pf_phoneformatted);
					//}
					return pf_phoneformatted;
				}
			};

			self.chili_iframe.chiliSetMaxLength = function (varname, varmaxlength) {
				console.log(varname + " maxlength: " + self.editor.GetObject("document.variables[" + varmaxlength + "].value"));
				self.editor.SetProperty("document.variables[" + varname + "]", "maxLength", editor.GetObject("document.variables[" + varmaxlength + "].value"));
			};
			*/
		},
		postMessageToParent(message) {
			parent.postMessage(message, "*");
		},
		revertDocument() {
			this.$confirm.require({
				header: "Revert",
				message: "Are you sure you want to undo all changes?",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					editor.ExecuteFunction("document", "Revert");
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
		saveDocument() {
			this.$confirm.require({
				header: "Save",
				message: "Are you sure you want to save?",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					self.chili_documentSaved = true;
					editor.ExecuteFunction("document", "Save");
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
	},
	mounted() {
		let self = this;

		//self.getChiliKey();

		if (!self.chili_document_id_temp) {
			self.copyChiliDocument().then((response) => {
				if (response && response.id) {
					console.log(response);
					self.chili_documentCopy = response;
					self.chili_editorURL = self.getChiliEditorURL();
				}
			});
		} else {
			self.chili_editorURL = self.getChiliEditorURL();
		}
	},
	created() {
		let self = this;
		window.OnEditorEvent = function (type, targetID) {
			console.log(type);
			switch (type) {
				case "DocumentFullyLoaded":
					self.postMessageToParent({
						action: "ready",
					});
					break;
				case "SelectedPageChanged":
					break;
				case "DocumentSaved":
					self.chiliSoapCall(
						"ResourceItemGetByIdOrPath",
						self.chili_key,
						[
							{
								name: "resourceName",
								value: "PdfExportSettings",
							},
							{
								name: "itemIdOrPath",
								value: self.chili_settings_id,
							},
						],
						true
					).then((response) => {
						console.log("ResourceItemGetByIdOrPath", response);
						if (response) {
							let PdfExportSettings_xml = response.xml;
							self.chiliSoapCall(
								"DocumentCreatePDF",
								self.chili_key,
								[
									{
										name: "itemID",
										value: self.chili_document_id_temp,
									},
									{
										name: "settingsXML",
										value: htmlEncode(
											PdfExportSettings_xml
										),
									},
								]
							).then((response) => {
								console.log("DocumentCreatePDF", response);
								if (response) {
									if (
										response &&
										response.task &&
										response.task["$"]
									) {
										self.postMessageToParent({
											action: "save",
											document:
												window.editor.GetObject(
													"document"
												),
											pdf: response.task["$"],
											chili_document_id:
												self.chili_document_id,
										});
									}
								}
							});
						}
					});
					break;
			}
		};

		window.addEventListener(
			"message",
			(event) => {
				let self = this;

				// Do we trust the sender of this message?
				//if (event.origin !== "http://localhost:8080" || event.origin !== "https://ppg-retail.nl") return;

				if (event.data.action == "loaded") {
					if (!self.parentReady) {
						if (event.data.variables) {
							event.data.variables.forEach((variable) => {
								window.editor.SetProperty(
									"document.variables[" + variable.name + "]",
									"value",
									variable.value
								);
								console.log(variable.name, variable.name);
							});
						}
						if (event.data.assets) {
							event.data.assets.forEach((asset) => {
								self.chiliSoapCall(
									"ResourceItemGetByPath",
									self.chili_key,
									[
										{
											name: "resourceName",
											value: "Assets",
										},
										{
											name: "itemPath",
											value: asset.path + asset.name,
										},
									]
								).then((response) => {
									console.log(
										"ResourceItemGetByPath",
										response
									);
									if (
										response &&
										response.item &&
										response.item["$"] &&
										response.item["$"].id
									) {
										self.chiliSoapCall(
											"ResourceItemDelete",
											self.chili_key,
											[
												{
													name: "resourceName",
													value: "Assets",
												},
												{
													name: "itemID",
													value: response.item["$"]
														.id,
												},
											]
										).then((response) => {
											console.log(
												"ResourceItemDelete",
												response
											);
											self.chiliSoapCall(
												"ResourceItemAddFromURL",
												self.chili_key,
												[
													{
														name: "resourceName",
														value: "Assets",
													},
													{
														name: "newName",
														value: asset.name,
													},
													{
														name: "folderPath",
														value: asset.path,
													},
													{
														name: "url",
														value: asset.url,
													},
												]
											).then((response) => {
												console.log(
													"ResourceItemAddFromURL",
													response
												);
												if (
													response &&
													response.item &&
													response.item["$"] &&
													response.item["$"]
														.relativePath
												) {
													window.editor.SetProperty(
														"document.variables[" +
															asset.variable +
															"]",
														"value",
														response.item["$"]
															.relativePath
													);
												}
											});
										});
									} else {
										self.chiliSoapCall(
											"ResourceItemAddFromURL",
											self.chili_key,
											[
												{
													name: "resourceName",
													value: "Assets",
												},
												{
													name: "newName",
													value: asset.name,
												},
												{
													name: "folderPath",
													value: asset.path,
												},
												{
													name: "url",
													value: asset.url,
												},
											]
										).then((response) => {
											console.log(
												"ResourceItemAddFromURL",
												"xxx",
												response
											);
											if (
												response &&
												response.item &&
												response.item["$"] &&
												response.item["$"].relativePath
											) {
												window.editor.SetProperty(
													"document.variables[" +
														asset.variable +
														"]",
													"value",
													response.item["$"]
														.relativePath
												);
											}
										});
									}
								});
							});
						}

						self.parentReady = true;
					}
				}

				console.log(event);
			},
			false
		);
	},
};
</script>
